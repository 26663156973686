<template>
  <b-container>
  <b-card class="mt-5 mb-5 px-4 py-4">
    <template v-slot:header>
      <h3 class="py-1 text-primary">التأمين على الإبل</h3>
    </template>
    <div class="pb-4">
      <p class="font-size-22 text-primary text-justify">هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن استخدام "هنا يوجد محتوى نصي، هنا يوجد محتوى نصي" فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء. العديد من برامح النشر المكتبي وبرامح تحرير صفحات الويب تستخدم لوريم إيبسوم بشكل إفتراضي كنموذج عن النص، وإذا قمت بإدخال "lorem ipsum" في أي محرك بحث ستظهر العديد من المواقع الحديثة العهد في نتائج البحث. على مدى السنين ظهرت نسخ جديدة ومختلفة من نص لوريم إيبسوم، أحياناً عن طريق الصدفة، وأحياناً عن عمد كإدخال بعض العبارات الفكاهية إليها.

      </p>
      <div class="mt-5">
        <router-link :to="{name: 'registerInsurance'}" class="w-100 d-flex justify-content-center"><b-button variant="primary" class="py-2 width-15 font-size-20">الحصول على التأمين</b-button></router-link>
      </div>
    </div>
  </b-card>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'

export default {
  mounted () {
    core.index()
  },
  data () {
    return {
    }
  }
}
</script>
<style>

</style>
